import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import "./ActivityReportForm.css"; // Import the CSS file

function ActivityReportForm() {
  const [signatureData, setSignatureData] = useState("");
  const canvasRef = useRef(null);
  const canvasWrapperRef = useRef(null);
  let drawing = false;

  const getCanvasPosition = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };
  };

  const startDrawing = (event) => {
    drawing = true;
    const context = canvasRef.current.getContext("2d");
    const { x, y } = getCanvasPosition(event);
    context.beginPath();
    context.moveTo(x, y);
  };

  const draw = (event) => {
    if (!drawing) return;
    const context = canvasRef.current.getContext("2d");
    const { x, y } = getCanvasPosition(event);
    context.lineTo(x, y);
    context.stroke();
  };

  const stopDrawing = () => {
    drawing = false;
  };

  const submitForm = (event) => {
    event.preventDefault();
    const dataUrl = canvasRef.current.toDataURL();
    setSignatureData(dataUrl);
    const loading = document.querySelector('#loading');

    loading.style.visibility = 'initial';

    const formData = new FormData(event.target);
    formData.append("signatureData", dataUrl);

    const emailParams = {
      "cliente-referencia": formData.get("cliente-referencia"),
      titulo: formData.get("titulo"),
      "primeiro-nome": formData.get("primeiro-nome"),
      "ultimo-nome": formData.get("ultimo-nome"),
      "fonte-cliente": formData.get("fonte-cliente"),
      "razoes-detalhes": formData.get("razoes-detalhes"),
      "data-relatorio": formData.get("data-relatorio"),
      signatureData: dataUrl,
    };

    emailjs
      .send(
        "service_1esncem",
        "template_5obltb9",
        emailParams,
        "tptoifpyPp3EAI3-X"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Formulário enviado com sucesso! A janela se fechará automaticamente.");
          window.close();
        },
        (error) => {
          console.error("FAILED...", error);
          alert("Erro ao enviar o email. Envie um email para: alfred@otsafe.com.br");
          loading.style.visibility = 'hidden';
        }
      );
  };

  return (
    <div className="form-container">
      <div id="loading">Aguarde Por Favor...</div>
      <h1 className="form-title">
        Formulário de Relatório de Atividade Suspeita
      </h1>

      <form onSubmit={submitForm} className="report-form">
        <label htmlFor="cliente-referencia" className="form-label">
          Cliente / Nº de Referência:
        </label>
        <input
          type="text"
          id="cliente-referencia"
          name="cliente-referencia"
          required
          className="form-input"
        />

        <label htmlFor="titulo" className="form-label">
          Título:
        </label>
        <input
          type="text"
          id="titulo"
          name="titulo"
          required
          className="form-input"
        />

        <label htmlFor="primeiro-nome" className="form-label">
          Primeiro Nome:
        </label>
        <input
          type="text"
          id="primeiro-nome"
          name="primeiro-nome"
          required
          className="form-input"
        />

        <label htmlFor="ultimo-nome" className="form-label">
          Último Nome:
        </label>
        <input
          type="text"
          id="ultimo-nome"
          name="ultimo-nome"
          required
          className="form-input"
        />

        <label htmlFor="fonte-cliente" className="form-label">
          Fonte do Cliente:
        </label>
        <input
          type="text"
          id="fonte-cliente"
          name="fonte-cliente"
          className="form-input"
        />

        <label htmlFor="razoes-detalhes" className="form-label">
          Relatório e Detalhes:
        </label>
        <textarea
          id="razoes-detalhes"
          name="razoes-detalhes"
          rows="5"
          cols="33"
          required
          className="form-textarea"
        ></textarea>

        <label htmlFor="data-relatorio" className="form-label">
          Data do relatório:
        </label>
        <input
          type="date"
          id="data-relatorio"
          name="data-relatorio"
          required
          className="form-input"
        />

        <label htmlFor="assinatura" className="form-label">
          Por favor assine aqui:
        </label>
        <div ref={canvasWrapperRef} className="canvas-wrapper">
          <canvas
            id="assinatura"
            ref={canvasRef}
            width="400"
            height="200"
            className="signature-canvas"
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={stopDrawing}
            onMouseOut={stopDrawing}
          ></canvas>
        </div>
        <input
          type="hidden"
          id="assinatura-data"
          name="assinatura-data"
          value={signatureData}
        />

        <input
          type="submit"
          value="Enviar Relatório"
          className="submit-button"
        />
      </form>
    </div>
  );
}

export default ActivityReportForm;
